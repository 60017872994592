import get from 'lodash/get';
import {
  getCheckedOutParticipants,
  getCheckedInParticipants,
  checkInParticipant,
  getCheckin,
  updateCheckin,
  createCheckin,
  deleteCheckin,
  getParticipantServicesByProgram,
  apiCheckoutRecord,
} from 'api/rollsheet';
import Types from '../types/rollsheet';
import NotificationHandler from 'components/Notification/NotificationHandler';

export const fetchCheckedOutParticipants = (program, q = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CHECKED_OUT_PARTICIPANTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getCheckedOutParticipants(program, q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CHECKED_OUT_PARTICIPANTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CHECKED_OUT_PARTICIPANTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CHECKED_OUT_PARTICIPANTS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchCheckedInParticipants = (program, q = '', page = 1) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CHECKED_IN_PARTICIPANTS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getCheckedInParticipants(program, q, page);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CHECKED_IN_PARTICIPANTS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CHECKED_IN_PARTICIPANTS_FAILURE,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_CHECKED_IN_PARTICIPANTS_FAILURE,
        message: error,
      });
    }
  };
};

export const checkInParticipantById = (program, participant) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.CHECKING_IN_PARTICIPANT_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await checkInParticipant(program, participant.id);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.CHECKING_IN_PARTICIPANT_SUCCESS,
        });
        const currentCheckedIns = get(
          getState(),
          'rollSheet.checkedInParticipants.data.data',
          []
        );
        const currentCheckedOuts = get(
          getState(),
          'rollSheet.checkedOutParticipants.data.data',
          []
        );
        dispatch({
          type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
          data: [
            ...currentCheckedIns,
            {
              ...participant,
              assigned_to: get(resp, 'data.assigned_to') || null,
              roll: {
                ...participant.role,
                checkin_id: get(resp, 'data.id', null),
                checked_in_at: get(resp, 'data.checked_in_at', null),
                checked_out_at: get(resp, 'data.checked_out_at', null),
              },
            },
          ],
        });
        dispatch({
          type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
          data: [...currentCheckedOuts.filter(f => f.id !== participant.id)],
        });
      } else {
        dispatch({
          type: Types.CHECKING_IN_PARTICIPANT_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.CHECKING_IN_PARTICIPANT_FAILURE,
        message: error,
      });
    }
  };
};

export const getCheckInDetails = (participant, checkinId) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_CHECKIN_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getCheckin(participant, checkinId);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_CHECKIN_DETAILS_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_CHECKIN_DETAILS_FAILURE,
          message: message,
        });
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.FETCH_CHECKIN_DETAILS_FAILURE,
        message: error,
      });
    }
  };
};

export const updateCheckInDetails = (
  participant,
  checkinId,
  data,
  operation,
  cb,
  hideNotification,
  otherOperation
) => {
  return async (dispatch, getState) => {
    dispatch({
      type: Types.UPDATE_CHECKIN_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const apiFn =
        otherOperation === 'checkoutRollsheet'
          ? apiCheckoutRecord
          : updateCheckin;
      const resp = await apiFn(participant, checkinId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_CHECKIN_DETAILS_SUCCESS,
        });
        const currentProgram = get(
          getState(),
          'userSettings.settings.rollSheet.program',
          ''
        );
        const currentCheckedIns = get(
          getState(),
          'rollSheet.checkedInParticipants.data.data',
          []
        );
        const currentParticipantIndex = currentCheckedIns.findIndex(
          p => p.id === participant
        );
        const currentCheckedOuts = get(
          getState(),
          'rollSheet.checkedOutParticipants.data.data',
          []
        );
        if (!operation) {
          dispatch({
            type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
            data: [
              ...currentCheckedOuts,
              ...(currentProgram === data.program_id
                ? [
                    {
                      ...currentCheckedIns[currentParticipantIndex],
                      roll: {
                        ...(currentCheckedIns[currentParticipantIndex] || {})
                          .roll,
                        checked_in_at: resp.data.checked_in_at,
                        checked_out_at: resp.data.checked_out_at,
                        checkin_id: resp.data.id,
                      },
                    },
                  ]
                : []),
            ],
          });
          dispatch({
            type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
            data: [...currentCheckedIns.filter(f => f.id !== participant)],
          });
        }
        if (operation === 'editCheckIn') {
          dispatch({
            type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
            data: [
              ...currentCheckedIns.slice(0, currentParticipantIndex),
              ...(currentProgram === data.program_id
                ? [
                    {
                      ...currentCheckedIns[currentParticipantIndex],
                      assigned_to: resp?.data?.assigned_to || null,
                      roll: {
                        ...(currentCheckedIns[currentParticipantIndex] || {})
                          .roll,
                        checked_in_at: resp.data.checked_in_at,
                        checked_out_at: resp.data.checked_out_at,
                        checkin_id: resp.data.id,
                      },
                    },
                  ]
                : []),
              ...currentCheckedIns.slice(currentParticipantIndex + 1),
            ],
          });
        }
        if (operation === 'editCheckOut') {
          const currentCheckedOutIndex = currentCheckedOuts.findIndex(
            p => p.id === participant
          );
          dispatch({
            type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
            data: [
              ...currentCheckedOuts.slice(0, currentCheckedOutIndex),
              ...(currentProgram === data.program_id
                ? [
                    {
                      ...currentCheckedOuts[currentCheckedOutIndex],
                      roll: {
                        ...(currentCheckedOuts[currentCheckedOutIndex] || {})
                          .roll,
                        requires_attention: false,
                        checked_in_at: resp.data.checked_in_at,
                        checked_out_at: resp.data.checked_out_at,
                        checkin_id: resp.data.id,
                      },
                    },
                  ]
                : []),
              ...currentCheckedOuts.slice(currentCheckedOutIndex + 1),
            ],
          });
        }
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.UPDATE_CHECKIN_DETAILS_FAILURE,
          message: message,
        });
        if (!hideNotification) {
          NotificationHandler.showError({
            description: message,
          });
        }
        if (cb) cb(false, resp);
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.UPDATE_CHECKIN_DETAILS_FAILURE,
        message: error,
      });
    }
  };
};

export const updateSelectedProgram = value => ({
  type: Types.UPDATE_ROLLSHEET_FILTERS,
  data: value,
});

export const handleCheckInAddedViaSocket = data => {
  return (dispatch, getState) => {
    const currentProgram = get(
      getState(),
      'userSettings.settings.rollSheet.program',
      ''
    );
    if (currentProgram && currentProgram === data.program.id) {
      const currentCheckedIns = get(
        getState(),
        'rollSheet.checkedInParticipants.data.data',
        []
      );
      const currentCheckedOuts = get(
        getState(),
        'rollSheet.checkedOutParticipants.data.data',
        []
      );
      const currentParticipantIndex = currentCheckedOuts.findIndex(
        p => p.id === data.participant.id
      );
      if (currentParticipantIndex > -1) {
        dispatch({
          type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
          data: [
            ...currentCheckedIns,
            {
              ...currentCheckedOuts[currentParticipantIndex],
              roll: {
                ...currentCheckedOuts[currentParticipantIndex].roll,
                requires_attention: false,
                checked_in_at: data.checkin.checked_in_at,
                checked_out_at: data.checkin.checked_out_at,
                checkin_id: data.checkin.id,
              },
            },
          ],
        });
        dispatch({
          type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
          data: [
            ...currentCheckedOuts.filter(f => f.id !== data.participant.id),
          ],
        });
      }
    }
  };
};
export const handleDeleteCheckInViaSocket = data => {
  return (dispatch, getState) => {
    const currentProgram = get(
      getState(),
      'userSettings.settings.rollSheet.program',
      ''
    );
    if (currentProgram && currentProgram === data.program.id) {
      if (!data.checkin.checked_out_at) {
        const currentCheckedOuts = get(
          getState(),
          'rollSheet.checkedOutParticipants.data.data',
          []
        );
        dispatch({
          type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
          data: [
            ...currentCheckedOuts.filter(f => f.id !== data.participant.id),
          ],
        });
      } else {
        const currentCheckedIns = get(
          getState(),
          'rollSheet.checkedInParticipants.data.data',
          []
        );
        dispatch({
          type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
          data: [
            ...currentCheckedIns.filter(f => f.id !== data.participant.id),
          ],
        });
      }
    }
  };
};

export const handleCheckInUpdateViaSocket = data => {
  return (dispatch, getState) => {
    const currentProgram = get(
      getState(),
      'userSettings.settings.rollSheet.program',
      ''
    );
    if (currentProgram && currentProgram === data.program.id) {
      if (data.checkin.checked_out_at) {
        if (!data.is_checkout) {
          const currentCheckedOuts = get(
            getState(),
            'rollSheet.checkedOutParticipants.data.data',
            []
          );
          const currentParticipantIndex = currentCheckedOuts.findIndex(
            p => p.id === data.participant.id
          );
          dispatch({
            type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
            data: [
              ...currentCheckedOuts.slice(0, currentParticipantIndex),
              {
                ...currentCheckedOuts[currentParticipantIndex],
                roll: {
                  ...currentCheckedOuts[currentParticipantIndex].roll,
                  requires_attention: false,
                  checked_in_at: data.checkin.checked_in_at,
                  checked_out_at: data.checkin.checked_out_at,
                  checkin_id: data.checkin.id,
                },
              },
              ...currentCheckedOuts.slice(currentParticipantIndex + 1),
            ],
          });
        } else {
          const currentCheckedIns = get(
            getState(),
            'rollSheet.checkedInParticipants.data.data',
            []
          );
          const currentCheckedOuts = get(
            getState(),
            'rollSheet.checkedOutParticipants.data.data',
            []
          );
          const currentParticipantIndex = currentCheckedIns.findIndex(
            p => p.id === data.participant.id
          );
          dispatch({
            type: Types.UPDATE_CHECKED_OUT_PARTICIPANTS,
            data: [
              ...currentCheckedOuts,
              {
                ...currentCheckedIns[currentParticipantIndex],
                roll: {
                  ...(currentCheckedIns[currentParticipantIndex] || {}).roll,
                  checked_in_at: data.checkin.checked_in_at,
                  checked_out_at: data.checkin.checked_out_at,
                  checkin_id: data.checkin.id,
                },
              },
            ],
          });
          dispatch({
            type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
            data: [
              ...currentCheckedIns.filter(f => f.id !== data.participant.id),
            ],
          });
        }
      } else {
        const currentCheckedIns = get(
          getState(),
          'rollSheet.checkedInParticipants.data.data',
          []
        );
        const currentParticipantIndex = currentCheckedIns.findIndex(
          p => p.id === data.participant.id
        );
        dispatch({
          type: Types.UPDATE_CHECKED_IN_PARTICIPANTS,
          data: [
            ...currentCheckedIns.slice(0, currentParticipantIndex),
            {
              ...currentCheckedIns[currentParticipantIndex],
              roll: {
                ...(currentCheckedIns[currentParticipantIndex] || {}).roll,
                checked_in_at: data.checkin.checked_in_at,
                checked_out_at: data.checkin.checked_out_at,
                checkin_id: data.checkin.id,
              },
            },
            ...currentCheckedIns.slice(currentParticipantIndex + 1),
          ],
        });
      }
    }
  };
};

export const addManualCheckIn = (participantId, data, cb, hideNotification) => {
  return async dispatch => {
    dispatch({
      type: Types.ADD_MANUAL_CHECKIN_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await createCheckin(participantId, data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.ADD_MANUAL_CHECKIN_DETAILS_SUCCESS,
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.ADD_MANUAL_CHECKIN_DETAILS_FAILURE,
          message: message,
        });
        if (!hideNotification) {
          NotificationHandler.showError({
            description: message,
          });
        }
        if (cb) cb(false, resp);
      }
      return resp;
    } catch (error) {
      dispatch({
        type: Types.ADD_MANUAL_CHECKIN_DETAILS_FAILURE,
        message: error,
      });
    }
  };
};

export const deleteTimeEntry = (participant, checkin) => {
  return async dispatch => {
    dispatch({
      type: Types.DELETE_CHECKIN_DETAILS_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await deleteCheckin(participant, checkin);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.DELETE_CHECKIN_DETAILS_SUCCESS,
          data: resp || {},
        });
        NotificationHandler.showSuccess({
          description: message,
        });
      } else {
        dispatch({
          type: Types.DELETE_CHECKIN_DETAILS_FAILURE,
          message: message,
        });
        NotificationHandler.showError({
          description: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.DELETE_CHECKIN_DETAILS_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchServicesForParticipantByProgramId = (
  participant,
  program
) => (q = '') => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_INPROGRESS,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipantServicesByProgram(
        participant,
        program,
        q
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_SUCCESS,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_FAILURE,
          message: message,
        });
      }
      return { resp };
    } catch (error) {
      dispatch({
        type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM_FAILURE,
        message: error,
      });
    }
  };
};

export const fetchMoreServicesForParticipantByProgramId = (
  participant,
  program
) => (q = '', page) => {
  return async dispatch => {
    dispatch({
      type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await getParticipantServicesByProgram(
        participant,
        program,
        q
      );
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.FETCH_MORE_SERVICES_BY_PARTICIPANT_PROGRAM,
          data: resp || {},
        });
      } else {
        dispatch({
          type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM,
          message: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.FETCH_SERVICES_BY_PARTICIPANT_PROGRAM,
        message: error,
      });
    }
  };
};
