import Request from './request';

export const getCheckedOutParticipants = (program, q, page) => {
  return Request.call({
    url: `/programs/${program}/checkouts`,
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const getCheckedInParticipants = (program, q, page) => {
  return Request.call({
    url: `/programs/${program}/checkins`,
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};

export const checkInParticipant = (program, participant) => {
  return Request.call({
    url: `/programs/${program}/checkin/${participant}`,
    method: 'POST',
  });
};

export const getCheckin = (participant, checkin) => {
  return Request.call({
    url: `/participants/${participant}/checkins/${checkin}`,
    method: 'GET',
  });
};

export const updateCheckin = (participant, checkin, data) => {
  return Request.call({
    url: `/participants/${participant}/checkins/${checkin}`,
    method: 'PUT',
    data,
  });
};
export const apiCheckoutRecord = (participant, checkin, data) => {
  return Request.call({
    url: `/participants/${participant}/checkins/${checkin}/checkout`,
    method: 'PUT',
    data,
  });
};

export const createCheckin = (participant, data) => {
  return Request.call({
    url: `/participants/${participant}/checkins`,
    method: 'POST',
    data,
  });
};

export const deleteCheckin = async (participant, checkin) => {
  return Request.call({
    url: `/participants/${participant}/checkins/${checkin}`,
    method: 'DELETE',
  });
};

export const getParticipantServicesByProgram = (
  participant,
  program,
  q = '',
  page = 1
) => {
  return Request.call({
    url: `/participants/${participant}/${program}/services/dropdown`,
    method: 'GET',
    params: {
      q: q,
      page,
    },
  });
};
